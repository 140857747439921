@use '../../../../shared/goals/tasks/tasks.scss';
@import '../../../../shared/layout/components/variables';

.tasks-case {
    .tasks-list {
        margin-bottom: 8em;

        .task-container {
            a {
                grid-column: 2;
            }
            .suggest-task {
                grid-column: 2;
            }
        }

    }
}

@media screen and (max-width: $minimum-viewport) {
    .tasks-case {
        .tasks-list {
            margin-bottom: 15em;
        }
        
        .buttons {
            bottom: 6.7em;
        }
    }
}