@import "../../shared/layout/components/variables";
$portrait-size: 2em;

.messages {
    .case {
        height: calc(100vh - 25em) !important;
    }

    &-list-case {
        height: calc(100vh - 19.5em) !important;
    }

    @media screen and (display-mode: browser) and (orientation: portrait) and (max-width: 750px) {
        .case {
            height: calc(100vh - 26.5em) !important;
        }

        &-list-case {
            height: calc(100vh - 22em) !important;
        }
    }

    .conversation-list {
        div {
            overflow-x: hidden !important;
        }

        &-case {
            margin-top: 5.3em;
        }

        &-item {
            padding: 0.5em 0 1em 1.5em;
            
            a {
                display: block;
                width: 90%;
            }
            .childname {
                font-weight: bolder;
                font-size: 1.5rem;
            }

            .info {
                position: relative;
                left: 2em;

                .icon {
                    right: 0;
                }
            }

            .portrait {
                position: absolute;
                left: 0.6em;
                width: $portrait-size;
                height: $portrait-size;
                border-radius: $portrait-size;
                display: inline-block;
                background-color: var(--color-yellow);
                text-align: center;
                vertical-align: middle;
                top: 1em;
                background-size: cover;
                border: 0.05em solid var(--color-yellow);
            }            
        }
    }

    &-create {
        select, .loader {
            width: 40%;
            height: 3.5em;
        }

        .dropdown {
            padding: 0 0 1em 0;
        }
    }
}

@media screen and (min-width: $minimum-viewport) {
    .messages {
        .case {
            height: calc(100vh - 18.8em) !important;
        }

        &-list-case {
            height: calc(100vh - 14em) !important;
        }
    }
}

@media screen and (max-width: $minimum-viewport) {
    .messages {
        .conversation-list {
            &-item {
                .timestamp {
                    width: 95%;
                }
            }
        }
    }
}