@use '../../../shared/goals/goals.scss';
@import '../../../shared/layout/components/variables';

.goals-case  {
    .goals-list {
        margin-bottom: 5em;
    }
}

@media screen and (max-width: $minimum-viewport){
    .goals-case {
        .goals-list {
            margin-bottom: 12em;
        }
        .buttons {
            bottom: 6.7em;
        }
    }
}
