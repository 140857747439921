@import '../../../shared/layout/components/variables';

.superuser {
    .superusers {
        &-list {
            .box {
                &-row {
                    grid-template-columns: 35% 53% 8%;

                    @media screen and (max-width: $minimum-viewport) {
                        grid-template-columns: 45% 39% 10%;
                    }
                }
            }
        }
    }
}
