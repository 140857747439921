@import "../../shared/layout/components/variables";
$header-background: blanchedalmond;
$portrait-background: aqua;
$portrait-size: 3.5em;

.child {
    .content {
        margin-top: 3em;
    }

    .case-list {
        display: flex;
        flex-direction: column;
        
        a {
            padding-left: 2em;
            &:last-child {
                margin-bottom: 2em;
            }
        }
    }

    .user-name {
        .info {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}