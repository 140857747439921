@use '../../shared/appointments/appointments.scss';
@import '../../shared/layout/components/variables';

.appointments {
    .next, .upcoming, .past {
            a {
            text-decoration: underline;
        }
    }

    .next {
        h2, p {
            margin: 0; 
        }
    }

    .upcoming, .past {
        margin-bottom: 2em;

        .child-name {
            font-weight: bold;
        }
        
        li {
            margin-top: 1.1em;
        }
    }
    
    .notes {
        height: 5em;
    }

    button {
        margin: 1em 0 0 0;
    }

    &-add {
        select {
            width: 40%;
        }
    }

    &-select {
        height: 67vh !important;
    }
    &-display {
        height: 45vh !important;
    }
}

@media screen and (max-width: $minimum-viewport) {
    .appointments{
        &-case {
            margin-bottom: 8em !important;
        }
    }
}
