@import '../../../shared/layout/components/variables';

.superuser {
    .cases-list {
        .box {
            &-row {
                grid-template-columns: 86% 8%;
                gap: 0;
                @media screen and (max-width: $minimum-viewport) {
                    grid-template-columns: 84% 8%;
                }
            }
        }

        .search-add-container {
            .search-container {
                width: 94%;

                @media screen and (max-width: $minimum-viewport) {
                    width: 100%;
                }
            }
        }
    }
}

.assign-child {
    h1 {
        line-height: 1.2em;
    }
    label {
        display: flex;
        flex-direction: column;
        gap: 1em;
        margin-bottom: 0.5em;

        select {
            font-size: 0.9em;
        }
    }
    .assign-button-container {
        margin-top: 1em;
    }
}