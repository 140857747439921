@import '../../shared/layout/components/variables';
.superuser {
    .user-name {
        display: flex;
        justify-content: center;
        margin-top: 2.8em;
    }
    
    .box {
        margin-bottom: 1em;

        &-row {
                display: grid;
                gap: 0.5em;
                grid-template-columns: 24% 64% 8%;

                .user {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &:not(:first-child) {
                    padding: 0.2em 0 0 0;
                }
            
                &:not(:last-child) {
                    border-bottom: var(--color-light-grey-dark) 0.08em solid;
                    padding: 0 0 0.2em;
                }

                a {
                    text-decoration: underline;
                }
            }
    }

    .password-updated-container {
        display: flex;
        gap: 0.3em;
        justify-content: center;
        flex-wrap: wrap;

        .displayed-password-container {
            display: flex;
            align-items: center;
            gap: 0.4em;

            i {
                height: 1.4rem;

                svg {
                    fill: var(--icon) !important;
                }
            }
        }
    }

    .button-container {
        margin-bottom: 1em;
    }
}



@media screen and (min-width: $minimum-viewport) {
    .superuser {
        .box {
            width: 94%;
        }
    }
}

@media screen and (max-width: $minimum-viewport) {
    .superuser {
        .box {
            &-row {
                grid-template-columns: 35% 49% 10%;
            }
        }
    }
}
